import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Camera, NO_CAMERA } from '../../../domain/models/interfaces/camera'
import useStorage from '../../../domain/services/useStorage'
import useCameras, { UseCamerasResult } from '../hooks/useCameras'

export interface UseCamerasContext extends UseCamerasResult {
  selectCamera: (camera: Camera) => void
  selectedCamera: Camera | null
}

const CamerasContext = createContext<UseCamerasContext | undefined>(undefined)

export const useCamerasContext = (): UseCamerasContext =>
  useContext(CamerasContext)

interface CamerasProviderProps {
  children: ReactNode
}

export const CamerasProvider: React.FC<CamerasProviderProps> = ({
  children,
}) => {
  const { loading, cameras, error, refresh } = useCameras()
  const [savedCamera, setSavedCamera] = useStorage('@camera', NO_CAMERA)
  const [selectedCamera, setSelectedCamera] = useState<Camera>(NO_CAMERA)

  useEffect(() => console.log('saved camera', savedCamera), [savedCamera])
  useEffect(
    () => console.log('selected camera', selectedCamera),
    [selectedCamera]
  )
  useEffect(() => console.log('cameras', cameras), [cameras])

  const selectCamera = (camera: Camera) => {
    setSelectedCamera(camera)
    setSavedCamera(camera)
  }

  useEffect(() => {
    console.log(
      savedCamera?.name,
      'vs',
      cameras.map((c) => c.name)
    )
    if (savedCamera === NO_CAMERA) return
    if (cameras.length === 0) return

    console.log('looking for matching camera')
    const matchingCamera = cameras.find(
      (camera) => camera.object.deviceId === savedCamera?.object?.deviceId
    )
    console.log('matching camera', matchingCamera)
    setSelectedCamera(matchingCamera ?? NO_CAMERA)
  }, [cameras, savedCamera])

  const context: UseCamerasContext = {
    loading,
    cameras,
    error,
    refresh,
    selectCamera,
    selectedCamera,
  }

  return (
    <CamerasContext.Provider value={context}>
      {children}
    </CamerasContext.Provider>
  )
}

export default CamerasProvider
