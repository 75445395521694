import { Box, HStack, VStack } from 'native-base'
import { useEffect, useState } from 'react'
import { ImageBackground, StyleSheet } from 'react-native'
import AttendeeRole from '../../../../../domain/enums/AttendeeRole'
import { AttendeeState30 } from '../../../../../domain/enums/AttendeeState'
import useRoomToasts from '../../../hooks/useRoomToasts'
import useSpeakingDetection from '../../../hooks/useSpeakingDetection'
import { useCamerasContext } from '../../../providers/CameraProvider'
import { useMicrophonesContext } from '../../../providers/MicrophoneProvider'
import { useRoomContext } from '../../../providers/Room30Provider'
import VideoFeed from '../VideoFeed'
import Chat from './Chat'
import LiveGroupModal from './LiveGroupModal.web'
import RoomFooter from './RoomFooter'
import Roster from './Roster'
import ScreenShareAlert from './ScreenShareAlert'

const BackgroundImage = require('../../../assets/images/room/background-beach.webp')

const DesktopRoom = ({ isLobbyRoom = false }) => {
  const { me, showChat, showParticipants, participants } = useRoomContext()
  const { isSpeaking } = useSpeakingDetection()

  const [isStartGroupModalOpen, setIsStartGroupModalOpen] = useState(false)
  const [isActionModalOpen, setIsActionModalOpen] = useState(false)

  useEffect(() => {
    if (me?.role === AttendeeRole.Moderator) {
      if (isLobbyRoom) {
        setIsStartGroupModalOpen(true)
        setIsActionModalOpen(false)
      } else if (me?.screen === AttendeeState30.Room) {
        setIsStartGroupModalOpen(false)
        setIsActionModalOpen(true)
      }
    }
  }, [isLobbyRoom])

  const footerHeight = 64
  const marginBottom = `${footerHeight + 4}px`

  const currentAudio = {
    enabled: me?.microphone,
    iMutedMyself: !me?.microphone,
  }

  const presenter =
    participants.find((participant) => participant.screenShare)?.alias || ''
  const isScreenSharing = !!presenter

  useRoomToasts({
    isAudioEnabled: currentAudio.enabled,
    iMutedMyself: currentAudio.iMutedMyself,
    isScreenSharing: isScreenSharing,
    personSharing: presenter,
    isSpeaking: isSpeaking,
  })

  const { refresh: refreshMicrophones } = useMicrophonesContext()
  useEffect(() => {
    refreshMicrophones()
  }, [])

  const { refresh: refreshCameras } = useCamerasContext()
  useEffect(() => {
    if (me?.role === AttendeeRole.Moderator) refreshCameras()
  }, [])

  return (
    <ImageBackground source={BackgroundImage} style={styles.backgroundImage}>
      <VStack flex={1} p={10}>
        <ScreenShareAlert />
        <HStack
          marginBottom={marginBottom}
          justifyContent="space-between"
          alignItems="center"
          flex={1}>
          {showParticipants ? (
            <Roster />
          ) : (
            <Box width="320px" backgroundColor="transparent" h="100%" />
          )}
          <Box
            alignSelf="flex-start"
            height="340px"
            maxWidth="640px"
            flex={1}
            mx={3}
            borderRadius={6}>
            <VideoFeed />
          </Box>
          {showChat ? (
            <Chat />
          ) : (
            <Box width="320px" backgroundColor="transparent" h="100%" />
          )}
        </HStack>
      </VStack>
      <Box
        position="absolute"
        bottom="0"
        h={`${footerHeight}px`}
        w="100%"
        bg="black"
        safeAreaBottom>
        <RoomFooter />
      </Box>
      {me?.role === AttendeeRole.Moderator && (
        <>
          <LiveGroupModal
            groupInactive={me.screen === AttendeeState30.Lobby}
            show={isStartGroupModalOpen}
            body="Lights, camera..."
            heading="Ready to start the group?"
          />
          <LiveGroupModal
            closeModal={() => setIsActionModalOpen(false)}
            show={isActionModalOpen}
            body="Action!"
            heading="You're live!"
          />
        </>
      )}
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
})

export default DesktopRoom
