import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Image, Pressable, Text } from 'native-base'
import { useWindowDimensions } from 'react-native'
import { CollectionCardProps } from '../../../../domain/models/interfaces/cardInterfaces'
import {
  useMobileBreakpoint,
  useTabletBreakpoint,
} from '../../../../domain/services/useBreakpoint'
import { maxStringLength } from '../../../helpers/maxStringLength'
import { NavigationParams } from '../../screens/NavigationScreen'

interface CollectionCard {
  collectionCard: CollectionCardProps
  mr?: number
  isCollectionsScreen?: boolean
}

const CollectionCard = ({
  collectionCard,
  mr = 0,
  isCollectionsScreen = false,
}: CollectionCard) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const { width } = useWindowDimensions()
  const isMobile = useMobileBreakpoint()
  const isTablet = useTabletBreakpoint()
  const isCollectionsScreenMobile = isMobile && isCollectionsScreen
  const cardDimensions = width / 2 - 24
  const numberOfVisibleCards = isTablet ? 2 : 1
  const margin = isTablet ? 40 : 24
  const cardWidthExploreMobile =
    isMobile &&
    !isCollectionsScreen &&
    (width - margin * 2 - (isTablet && 8)) / numberOfVisibleCards

  let cardWidth: string | number | undefined
  if (isMobile && !isCollectionsScreen) {
    cardWidth = cardWidthExploreMobile
  } else if (isCollectionsScreenMobile) {
    cardWidth = cardDimensions
  } else {
    cardWidth = '304px'
  }

  return (
    <Pressable
      mr={mr}
      width={cardWidth}
      onPress={() =>
        navigation.navigate('Collection', { slug: collectionCard.slug })
      }>
      <Image
        borderRadius={'8px'}
        width="100%"
        height={isCollectionsScreenMobile ? cardDimensions : '140px'}
        source={{ uri: collectionCard.thumbnail }}
        alt={collectionCard.title}
        mb={3}
      />
      <Text
        fontSize={isCollectionsScreen ? '16px' : '14px'}
        fontWeight={'medium'}
        lineHeight="1.3em">
        {maxStringLength(collectionCard.title, 30)}
      </Text>
      {isCollectionsScreen && !isMobile && (
        <Text mb={4} fontSize="12px">
          {collectionCard.summary}
        </Text>
      )}
      {!isCollectionsScreen && (
        <Text fontSize="12px">
          {maxStringLength(collectionCard.summary, 50)}
        </Text>
      )}
    </Pressable>
  )
}

export default CollectionCard
