import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  Microphone,
  NO_MICROPHONE,
} from '../../../domain/models/interfaces/microphone'
import useStorage from '../../../domain/services/useStorage'
import useMicrophones, { UseMicrophonesResult } from '../hooks/useMicrophones'

export interface UseMicrophonesContext extends UseMicrophonesResult {
  selectMicrophone: (Microphone) => void
  selectedMicrophone: Microphone | null
}

const MicrophonesContext = createContext<UseMicrophonesContext | undefined>(
  undefined
)

export const useMicrophonesContext = (): UseMicrophonesContext =>
  useContext(MicrophonesContext)

interface MicrophonesProviderProps {
  children: ReactNode
}

export const MicrophonesProvider: React.FC<MicrophonesProviderProps> = ({
  children,
}) => {
  const { loading, microphones, error, refresh } = useMicrophones()
  const [savedMicrophone, setSavedMicrophone] = useStorage(
    '@microphone',
    NO_MICROPHONE
  )
  const [selectedMicrophone, setSelectedMicrophone] =
    useState<Microphone>(NO_MICROPHONE)

  useEffect(
    () => console.log('saved microphone', savedMicrophone),
    [savedMicrophone]
  )
  useEffect(
    () => console.log('selected microphone', selectedMicrophone),
    [selectedMicrophone]
  )
  useEffect(() => console.log('microphones', microphones), [microphones])

  const selectMicrophone = (microphone: Microphone) => {
    setSelectedMicrophone(microphone)
    setSavedMicrophone(microphone)
  }

  useEffect(() => {
    console.log(
      savedMicrophone?.name,
      'vs',
      microphones.map((c) => c.name)
    )
    if (savedMicrophone === NO_MICROPHONE) return
    if (microphones.length === 0) return

    console.log('looking for matching microphone')
    const matchingMicrophone = microphones.find(
      (microphone) =>
        microphone.object.deviceId === savedMicrophone?.object?.deviceId
    )
    console.log('matching microphone', matchingMicrophone)
    setSelectedMicrophone(matchingMicrophone ?? NO_MICROPHONE)
  }, [microphones, savedMicrophone])

  const context: UseMicrophonesContext = {
    loading,
    microphones,
    error,
    refresh,
    selectMicrophone,
    selectedMicrophone,
  }

  return (
    <MicrophonesContext.Provider value={context}>
      {children}
    </MicrophonesContext.Provider>
  )
}
